<template>
  <div class="d-flex align-center">
    <v-btn v-if="isBool" @click.stop="selectAction"
           :color="!disabled ? checkBoxColor : checkBoxColor + '66'"
           :small="small" icon class="mr-1"
           :disabled="disabled"
    >
      <v-icon
          :color="!disabled ? checkBoxColor : checkBoxColor + '66'"
          :small="small"
          :size="size"
      >
        {{ checkBoxIcon }}
      </v-icon>

    </v-btn>
    <div @click="selectAction"
         :class="[{'pointer' : !disabled}]"
         :style="error ? `color : ${wsWARNING} !important ` : null"
    >
      <slot name="default">
        <h5 v-if="label"
            :style="disabled ? `color : ${checkBoxColor + '66'}` : textColor ? `color : ${textColor}` : null" >
          {{ label }}
        </h5>
      </slot>
    </div>
  </div>


</template>

<script>
export default {
  name: "wsCheckBox",
  props : {
    isBool : {
      type : Boolean,
      default : true
    },
    small : {
      type : Boolean,
      default : true
    },
    semiSelected : {
      type : Boolean,
      default : false
    },
    color : {
      type : String,
    },
    selectedColor : {
      type : String,
    },
    textColor : {
      type : String,
    },
    label : {
      type : String,
    },
    value : {},
    preventSelect : {
      type : Boolean,
      default : false
    },
    error : {
      type : Boolean,
      default : false
    },
    disabled : {
      type : Boolean,
      default : false
    },
    radio : {
      type : Boolean,
      default : false
    },
    size : {}

  },
  data() {
    return {
      selected : false
    }
  },
  computed : {
    checkBoxIcon() {

      if ( this.radio ) {
        return this.selected ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank'
      }

      if ( this.semiSelected ) {
        return 'mdi-checkbox-intermediate'
      }
      return this.selected ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'
    },
    checkBoxColor() {
      if ( this.error ) {
        return this.wsWARNING
      }
      return this.selected ? (this.selectedColor || 'green lighten-2') : (this.color || 'grey darken-1')
    }
  },
  watch: {
    value(value) {
      if ( this.selected !== value) {
        this.selected = value
      }
    }
  },

  methods : {
    selectAction() {
      if ( this.disabled) {
        return
      }
      if ( this.preventSelect ) {
        this.$emit('prevent')
        return
      }
      this.selected = !this.selected
      this.$emit('input',this.selected)
    }
  },
  mounted() {
    if (this.isBool && this.value ) {
      this.selected = this.value
    }
  }
}
</script>

<style scoped>

</style>